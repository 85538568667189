import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { RestorationVendor } from './restoration-vendor.model';
import { RestorationCategory } from './restoration-category.model';
import { User } from './user.model';
import { RestorationType } from './restoration-type.model';
import { Job, JobRestorationMediaVM } from '.';

@Entity({ modelName: 'JobRestoration' })
export class JobRestoration {
  @Key id?: string;
  job: string;
  employee: string;
  vendor: number;
  restorationType: number;
  category: number;
  description: string;
  dim1: string;
  dim2: string;
  dim3: string;
  created?: string;
  date: string;
  status?: string;
  finishByType?: string;
  finishByDate?: string;
}

export interface JobRestorationVM {
  id?: string;
  job: string;
  employee: User;
  vendor: RestorationVendor;
  restorationType: RestorationType;
  category: RestorationCategory;
  description: string;
  dim1: string;
  dim2: string;
  dim3: string;
  created?: Date;
  date: Date;
  status?: string;
  finishByType?: string;
  finishByDate?: Date;
}

export interface JobRestorationTicketVM {
  id?: string;
  job: Job;
  employee: User;
  vendor: RestorationVendor;
  restorationType: RestorationType;
  category: RestorationCategory;
  description: string;
  dim1: string;
  dim2: string;
  dim3: string;
  created?: Date;
  date: Date;
  status?: string;
  finishByType?: string;
  finishByDate?: Date;
  media: JobRestorationMediaVM[];
}

export interface JobRestorationTicketJobVM extends Job {
  tickets: JobRestorationTicketVM[];
  minDate: Date;
  maxDate: Date;
}

export interface JobRestorationStatusUpdate {
  tickets: string[];
  status: string;
  finishByType?: string;
  finishByDate?: string;
  vendor?: number;
  sendEmail?: boolean;
}
